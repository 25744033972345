import React from 'react';
import './Hodiny.css';

const lectures = [
  {
    id: 1,
    title: "Úvod do algoritmizace",
    materials: [
      { type: "Slajdy", color: "blue", url: "hodina1.pdf" },
      { type: "Záznam", color: "red", url: "https://nextcloud.skovar.cz/s/ortJXHTPgSAe3ra" },
    ],
    tags: [
      //{ name: "bandits", url: "#" },
      //{ name: "algorithms", url: "#" },
      ]
  },

  {
    id: 2,
    title: "Základní algoritmy, binární vyhledávání",
    materials: [
      { type: "Slajdy", color: "blue", url: "hodina2.pdf" },
      { type: "Záznam", color: "red", url: "https://nextcloud.skovar.cz/s/tRCEjQEH3CNj4M8" },
      { type: "Úkol", color: "yellow", url: "https://seminar.skovar.cz/ukoly/#assignment-1" },
    ],
    tags: [
      //{ name: "bandits", url: "#" },
      //{ name: "algorithms", url: "#" },
      ]
  },
  // Add more lectures here...
];

function TagButton({ tag }) {
  return (
    <a href={tag.url} className="tag-button">
      {tag.name}
    </a>
  );
}
function MaterialButton({ material }) {
  return (
    <a href={material.url} className={`material-button ${material.color}`}>
      {material.type}
    </a>
  );
}

function LectureItem({ lecture }) {
  return (
    <div className="lecture-item">
      <h3>{lecture.id}. {lecture.title}</h3>
      <div className="materials">
        {lecture.materials.map((material, index) => (
          <MaterialButton key={index} material={material} />
        ))}
      </div>
      <div className="tags">
        {lecture.tags.map((tag, index) => (
          <TagButton key={index} tag={tag} />
        ))}
      </div>
    </div>
  );
}

function Hodiny() {
  return (
    <section id="hodiny" className="section">
      <h2>Hodiny</h2>
      <div className="lecture-list">
        {lectures.map(lecture => (
          <LectureItem key={lecture.id} lecture={lecture} />
        ))}
      </div>
    </section>
  );
}

export default Hodiny;
