import React, { useContext } from 'react';


function Home() {

  return (
    <section className="section home" >
      <h1>Seminář z informatiky</h1>
      <div class="subheading">Školní rok 2024/2025</div>
      <div class="subheading">Gymnázium Nad Kavalírkou</div>
      <div class="subheading">Štěpán Kovář</div>
      <h2>Sylabus:</h2>
        <p>Základní pojmy - algoritmus, časová složitost, datová struktura, pseudokód</p>
        <p>Programování v Pythonu</p>
        <p>Jednoduché algoritmické problémy - třídění, vyhledávání, rekurze</p>
        <p>Matematické aplikace v informatice - prvočíselný rozklad, hledání společného dělitele, šifrovací protokol RSA</p>
        <p>Grafy - terminologie, reprezentace, algoritmy</p>
        <p>Známé algoritmické problémy</p>
      
    </section>
  );
}

export default Home;
