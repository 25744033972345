import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from './LanguageContext';
import { translations } from './translations';

function Header() {
  const { language, setLanguage } = useContext(LanguageContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const t = translations[language];

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && menuOpen) {
        setMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [menuOpen]);

  return (
    <header>
      <div className="header-content">
        <button className="menu-toggle" onClick={toggleMenu}>
          ☰
        </button>
        <nav className={menuOpen ? 'open' : ''}>
          <ul>
            <li><Link to="/" onClick={() => setMenuOpen(false)}>O předmětu</Link></li>
            <li><Link to="/hodiny" onClick={() => setMenuOpen(false)}>Hodiny</Link></li>
            <li><Link to="/znamkovani" onClick={() => setMenuOpen(false)}>Známkování</Link></li>
            <li><Link to="/ukoly" onClick={() => setMenuOpen(false)}>Úkoly</Link></li>
            <li><Link to="/materialy" onClick={() => setMenuOpen(false)}>Materiály</Link></li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
