import React, { useContext, useState } from 'react';
import './styles.css';

function Materialy() {
  return (
    <section id="materialy" className="section materialy">
      <h2>Materiály</h2>
      <a href="/pruvodce_labyrintem_algoritmu.pdf" className="button-materialy">Průvodce labyrintem algoritmů</a>
    </section>
  );
}
export default Materialy;
