import React, { createContext, useState, useEffect } from 'react'; import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { LanguageProvider } from './LanguageContext.js';
import Header from './Header.js';
import Home from './Home.js';
import Hodiny from './Hodiny.js';
import Znamkovani from './Znamkovani.js';
import Materialy from './Materialy.js';
import Ukoly from './Ukoly.js';
import './styles.css';



function App() {

  return (
    <LanguageProvider>
      <Router>
        <div className="App">
          <Header />
          <main>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/hodiny" component={Hodiny} />
              <Route path="/znamkovani" component={Znamkovani} />
              <Route path="/ukoly" component={Ukoly} />
              <Route path="/materialy" component={Materialy} />
            </Switch>
          </main>
        </div>
      </Router>
    </LanguageProvider>
  );
}

export default App;
