import React, { useState } from 'react';
import './homework-styles.css';
import { ChevronDown, ChevronUp, Calendar, Award, FileText } from 'lucide-react';

const HomeworkItem = ({ id, title, description, deadline, points, templateLink }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div id={`assignment-${id}`} className="border rounded-lg mb-4 overflow-hidden">
      <div 
        className="flex justify-between items-center p-4 bg-gray-100 cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <h3 className="text-lg font-semibold">{title}</h3>
        <div className="flex items-center space-x-4">
          <span className="flex items-center">
            <Calendar className="w-4 h-4 mr-1" />
            {deadline}
          </span>
          <span className="flex items-center">
            <Award className="w-4 h-4 mr-1" />
            {points} bodů
          </span>
          {isExpanded ? <ChevronUp /> : <ChevronDown />}
        </div>
      </div>
      {isExpanded && (
        <div className="p-4">
          <div 
            className="mb-4" 
            dangerouslySetInnerHTML={{ __html: description }} 
          />
          <a 
            href={templateLink} 
            target="_blank" 
            rel="noopener noreferrer"
            className="inline-flex items-center text-blue-600 hover:underline"
          >
            <FileText className="w-4 h-4 mr-1 template" />
            Začněte s šablonou</a>
        </div>
      )}
    </div>
  );
};

const Ukoly = () => {
  const homeworks = [
    {
      id: 1,
      title: "Úkol 1: Součin prvků až na aktuální",
      description: `
        <p class="description p">Cílem tohoto úkolu je vytvořit funkci <code class="description-code">product_except_self</code>, která přijme seznam celých čísel a vrátí nový seznam, kde každý prvek na indexu i obsahuje součin všech čísel ze vstupního seznamu kromě čísla na indexu i. Při řešení tohoto problému je zakázáno používat operaci dělení.</p>
        <h3 class= "description h3">Popis funkce:</h3>
        <p class="description p">Funkce <code class="description-code">product_except_self</code> by měla projít seznam <code class="description-code">nums</code> a pro každý prvek vypočítat produkt ostatních prvků. Výsledný seznam by měl mít stejnou délku jako vstupní seznam a na každé pozici <code class="description-code">i</code> by měl obsahovat produkt všech prvků seznamu <code class="description-code">nums</code> kromě <code class="description-code">nums[i]</code>.</p>
        <h3 class="description h3">Příklad:</h3>
        <p class="description p">Vstup: <code class="description-code">nums = [1, 2, 3, 4]</code><br>Výstup: <code class="description-code">[24, 12, 8, 6]</code><br>
        V tomto příkladu, pro první prvek (1), výstupní hodnota je součin 2 * 3 * 4 = 24, a tak dále pro ostatní prvky.</p>
        <h3 class="description h3">Asymptotická složitost:</h3>
        <p class="description p">Uveďte v kódu také, v jakém asymptoticky času pracuje váš algoritmus. Nyní se ještě nemusíte snažit, aby váš kód byl co nejefektivnější. Zajímá mě jen  analýza vašeho kódu.</p>
        <h3 class="description h3">Unit Testy:</h3>
        <p class="description p">Po napsání funkce byste měli ověřit její správnost spuštěním připravených unit testů, které ověří funkčnost kódu v různých scénářích.</p>
        <h3 class="description h3">Odevzdání:</h3>
        <p class="description p">Odevzdejte své řešení ve formě souboru Pythonu, který obsahuje funkci <code class="description-code">product_except_self</code> spolu s testy. Ujistěte se, že váš kód úspěšně prochází všemi testy před odevzdáním. Odevzdejte do Teams.</p>
      `,
      deadline: "29. 9. 2024", 
      points: 5,
      templateLink: "assignments/product_exept_self.py"
    },
    //{
    //  id: 2,
    //  title: "Homework 2: State and Props",
    //  description: "Build a counter component using React state and pass the count as a prop to a child component.",
    //  deadline: "2024-09-22",
    //  points: 15,
    //  templateLink: "https://example.com/hw2-template"
    //},
    // Add more homework items as needed
  ];

  return (
    <section id="ukoly" className="section p-6">
      <h2 className="text-2xl font-bold mb-6">Úkoly</h2>
      <h3 className="description h3">Pravidla:</h3>
      <ul>
        <li> Úkoly můžete odevzdávat do 23:59 v den odevzdání. Kdyby se ale cokoli dělo (nad něčím jste se zasekli, jste u babičky atd.), rád vám deadline prodloužím. Platí však, že mi o tom musíte napsat před deadlinem. </li>
        <li> Na úkolech můžete spolupracovat a bavit se o jejich řešení, ale platí, že kód píše každý sám. Ne společně. </li>
        <li> Všechny internetové zdroje jsou samozřejmě povoleny. </li>
        <li> Generativní AI modely vám sice zakázat nemohu (ani nemám jak), ale doporučuji vám je nepoužívat. Půjde o základní a zásadní koncepty, které je opravdu dobré si osvojit vlastními silami. </li>
      </ul>

      <div className="space-y-4">
        {homeworks.map((homework) => (
          <HomeworkItem key={homework.id} {...homework} />
        ))}
      </div>
    </section>
  );
};

export default Ukoly;
